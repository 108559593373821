import React, { useEffect } from 'react';
import { Link as RouterLink, navigate, useLocation } from '@reach/router';
import { useTheme } from '@mui/styles';
import { useSnackbar } from '@components/common/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import { makePostRequest } from '@helpers/requests';
import { AUTH_PASSWORD_RESET } from '@helpers/api';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, CircularProgress, Grid, Link } from '@mui/material';
import styles from './styles';
import Typography from '@mui/material/Typography';
import { TextField } from 'formik-mui';
import Layout from '@components/common/Layouts/Landing';

const ForgotPassword: React.FC = () => {
	const theme = useTheme();
	const location: any = useLocation();
	const [openSnackbar] = useSnackbar();
	const dispatch = useDispatch();

	const { user } = useSelector(({ user }) => ({ user }));

	useEffect(() => {
		if (user) {
			navigate('/');
			return;
		}
	}, [user]);

	const handleSubmit = async (values) => {
		try {
			await makePostRequest(AUTH_PASSWORD_RESET, values);
			openSnackbar(
				`A reset password email has been sent to ${values?.email} if this user exists.`,
				'success'
			);
		} catch (error) {
			openSnackbar(
				error?.errorMessage ??
					`An error ocurred attempting to reset the password for ${user?.email}.`,
				'error'
			);
		}
	};

	const initialValues: { email: string } = { email: '' };

	// @ts-ignore
	return (
		<Layout>
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					email: Yup.string().email('Must be an email').required('Email is required'),
				})}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Grid
						css={styles(theme)}
						container
						direction="column"
						alignItems="center"
						justifyContent="center"
					>
						<Grid item>
							<Form className="form">
								<Typography component="h1" variant="h5">
									Reset your password
								</Typography>
								<Field
									component={TextField}
									name="email"
									type="email"
									label="Email"
									fullWidth
									autoComplete="email"
									margin="normal"
								/>

								<Button
									type="submit"
									className="submit"
									fullWidth
									variant="contained"
									disabled={isSubmitting}
									startIcon={
										isSubmitting ? (
											<CircularProgress color="inherit" size={20} />
										) : undefined
									}
								>
									Submit
								</Button>
							</Form>

							<Typography>
								Did you remember your password?{' '}
								<Link
									component={RouterLink}
									to="/login"
									state={{ from: location.state?.from }}
								>
									Log in here
								</Link>
								.
							</Typography>
						</Grid>
					</Grid>
				)}
			</Formik>
		</Layout>
	);
};

export default ForgotPassword;
